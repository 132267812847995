import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppService } from './app.service';
import { ContentUrlService } from './content-url.service';
import { ProdGenApi } from '../apiService/prodgen.api';
import { Setting } from '../apiService/classFiles/class.organizations';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PeakRedirectGuard implements CanActivate {
  constructor(private router: Router, private appService: AppService, private contentUrlService: ContentUrlService, private pinnacleService: ProdGenApi) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

      // CR - 2025-02-06 Due to an issue that casued notification emails to go out
      // pointing to content in the beta site, we are going to redirect any
      // users that arrive here with a content or site link to production.
      // This is taking into account if a page is reloaded...it will remain on the current site (don't want to redirect if user hits refresh)
      if (performance.navigation.type !== performance.navigation.TYPE_RELOAD && this.isAuthorized() == false) {
          let betaSiteUrl = "https://portalbeta.pinnacleseries.com/#/";
          //let betaSiteUrl = "http://localhost:4200/#/";

          console.log(window.location.href);

          let prodSiteUrl = "https://portal.pinnacleseries.com/#/";
          if ((window.location.href.includes(betaSiteUrl) || window.location.href.includes("https://prodgenbrowserbeta.azurewebsites.net/#/"))
              && !window.location.href.includes("/home")
              && !window.location.href.includes("/login")
          ) {

              let location = window.location.href;
              location = location.replace("https://prodgenbrowserbeta.azurewebsites.net/#/", prodSiteUrl);
              location = location.replace(betaSiteUrl, prodSiteUrl);
              console.log("Redirecting to: " + location);

              window.location.href = location;
              //window.open(location, "_self");
              return;
          }
      }



    const getFullUrlPath = (routeSnapshot: ActivatedRouteSnapshot): string => {
        let fullPath = routeSnapshot.url.map(segment => segment.path).join('/');
        
        if (routeSnapshot.parent) {
          fullPath = getFullUrlPath(routeSnapshot.parent) + '/' + fullPath;
        }
        
        return fullPath;
    };
    
    const fullUrlPath = getFullUrlPath(route);
    const queryParams = route.queryParams;
    const queryString = new URLSearchParams(queryParams).toString();
    const fragment = route.fragment;    
    const fullUrlWithParams = queryString ? `${fullUrlPath}?${queryString}` : fullUrlPath;
    const fullUrlWithFragment = fragment ? `${fullUrlWithParams}#${fragment}` : fullUrlWithParams;    
    const fullAbsoluteUrl = `${window.location.origin}/${fullUrlWithFragment}`;
    
    let isAuthorized = this.isAuthorized();
    if (!isAuthorized) {
        if (this.contentUrlService.isRedirectUrlToClassicContent(fullAbsoluteUrl) ||
            this.contentUrlService.isRedirectUrlToPeakContent(fullAbsoluteUrl)) {
            console.log("set LoginURL guard 41" + fullUrlWithFragment);
            localStorage.setItem("LoginURL", fullUrlWithFragment);
            this.router.navigate(['/login'], { queryParams: { contentRedirectUrl: fullUrlWithFragment } });
        }
        else {
            let loginUrl = localStorage.getItem("LoginURL");
            if (!loginUrl) {
              console.log("set LoginURL guard 48" + fullUrlWithFragment);
              localStorage.setItem("LoginURL", fullUrlWithFragment);
            }
            this.router.navigate(['/login']);
        }

        return of(false);
    }
        
    if (this.appService.haveSetPeakExperienceEnabled) {
        if (this.appService.peakExperienceEnabled) {
            setTimeout(() => {
                this.contentUrlService.routeToPeakUrlFromPinnacleUrl(fullAbsoluteUrl);
            }, 0);
            
            return of(false); // to prevent navigation within Angular
        }

        return of(true); // allow navigation
    }
    else {
        return this.pinnacleService.GetCurrentTenantSettings().pipe(
            switchMap(settings => {
              let isPeakSetting: Setting = settings.find(v => v.name === "PEAK_USER_ENABLED");
              if (isPeakSetting) {
                this.appService.peakExperienceEnabled = isPeakSetting.settingValue == 1;
              } else {
                this.appService.peakExperienceEnabled = false;
              }
      
              if (this.appService.peakExperienceEnabled) {
                setTimeout(() => {
                  this.contentUrlService.routeToPeakUrlFromPinnacleUrl(fullAbsoluteUrl);
                }, 0);
                return of(false); // to prevent navigation within Angular
              }
      
              return of(true); // allow navigation
            })
          );
    }
  }

  isAuthorized(): boolean {
    return ProdGenApi.getUserAccessKey().length > 0;
  }
}
